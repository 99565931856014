import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CheckboxField, Alert as RawAlert } from './swegon-compat/components';
import { ChevronDownIcon } from './swegon-compat/icons';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    DeleteTagModal,
    DuplicateTagModal,
    EditTagModal,
    NewProjectModal,
    AddProductModal,
} from './modals';

const tailwindResolvedConfig = resolveConfig(tailwindConfig);

export * from './swegon-compat/components';

export const QuickAlert = (props) => {
    const { kind, msg } = props;

    const [show, setShow] = React.useState(true);

    if (!show) return null;

    return (
        <RawAlert
            kind={kind}
            msg={msg}
            onClose={() => setShow(false)}
        />
    );
};

export const CollapsiblePanel = (props) => {
    const {
        header: HeaderComponent,
        title,
        children,
        defaultOpen,
        bordered,
        showWarning,
    } = props;
    const [open, setOpen] = React.useState(defaultOpen ?? false);

    return (
        <div className={classNames({ 'border border-black-20 p-4': bordered })}>
            <HeaderComponent
                open={open}
                title={title}
                onClick={() => setOpen(!open)}
                showWarning={showWarning}
            />
            <div className={classNames({ hidden: !open })}>{children}</div>
        </div>
    );
};

export const CollapsiblePanelHeader = (props) => {
    const { open, title, onClick, showWarning } = props;

    return (
        <div
            className="flex flex-wrap justify-between items-center pb-2 mb-2 text-2xl font-bold border-b border-black-20"
            onClick={onClick}
        >
            {showWarning && (
                <div className="flex flex-row">
                    <div>{title}</div>
                    <div className="ml-2 text-yellow-100">{'*'}</div>
                </div>
            )}
            {!showWarning && <div>{title}</div>}
            <ChevronDownIcon
                className={classNames({ 'transform rotate-180': open })}
            />
        </div>
    );
};

export const useTabs = (tabs, contentFn) => {
    const [activeTabKey, setActiveTabKey] = React.useState(
        tabs.find((t) => t.isDefault)?.key,
    );

    const TabsContainer = (
        <div className="flex flex-wrap">
            {tabs.map((tab) => (
                <div
                    key={tab.key}
                    className={classNames(
                        'leading-7 font-bold text-lg px-4 py-2 hover:bg-green-secondary cursor-pointer',
                        activeTabKey === tab.key
                            ? 'text-green-dark underline decoration-4 underline-offset-8'
                            : 'text-black-75',
                    )}
                    onClick={() => setActiveTabKey(tab.key)}
                >
                    {tab.title}
                </div>
            ))}
        </div>
    );

    return [TabsContainer, contentFn(activeTabKey)];
};

const TabValueContext = React.createContext();
const SetTabValueContext = React.createContext();

const TabsContext = (props) => {
    const { defaultValue, children } = props;
    const [tabValue, setTabValue] = React.useState(defaultValue);

    return (
        <SetTabValueContext.Provider value={setTabValue}>
            <TabValueContext.Provider value={tabValue}>
                {children}
            </TabValueContext.Provider>
        </SetTabValueContext.Provider>
    );
};

const TabClassNamesContext = React.createContext();

const TabList = (props) => {
    const { tabClassNames, activeTabClassNames, children } = props;

    return (
        <TabClassNamesContext.Provider
            value={[tabClassNames, activeTabClassNames]}
        >
            <div className="flex flex-wrap flex-row">{children}</div>
        </TabClassNamesContext.Provider>
    );
};

const Tab = (props) => {
    const { label, value: propsValue } = props;

    const tabValue = React.useContext(TabValueContext);
    const setTabValue = React.useContext(SetTabValueContext);
    const [tabClassNames, activeTabClassNames] =
        React.useContext(TabClassNamesContext);

    return (
        <div
            className={classNames(
                'font-bold text-lg text-black-75 px-4 py-2 hover:bg-green-secondary cursor-pointer',
                tabClassNames,
                tabValue === propsValue &&
                    'text-green-dark underline decoration-4 underline-offset-8',
                tabValue === propsValue && activeTabClassNames,
            )}
            onClick={() => setTabValue(propsValue)}
        >
            {label}
        </div>
    );
};

const TabPanel = (props) => {
    const { value: propsValue, children } = props;

    const tabValue = React.useContext(TabValueContext);

    return propsValue === tabValue ? children : null;
};

export const Tabs = {
    Context: TabsContext,
    List: TabList,
    Tab: Tab,
    Panel: TabPanel,
};

export const ToolTip = (props) => {
    const { anchor, place, children, noArrow } = props;
    const wrapperId = `tool-tip-${uuidv4()}`;
    return (
        <div id={wrapperId}>
            {anchor}
            <ReactTooltip
                className={classNames(
                    '!text-black-75 !opacity-100 !bg-white !filter !shadow-swegon1 !rounded-none !z-10',
                )}
                classNameArrow={classNames('!border-2 !border-white')}
                anchorSelect={`#${wrapperId}`}
                place={place}
                openOnClick="true"
                noArrow={noArrow}
                arrowColor={tailwindResolvedConfig.theme.colors.black[2]}
                clickable="true"
            >
                {children}
            </ReactTooltip>
        </div>
    );
};

// export const TagContextMenu = (props) => {
//     const { anchor, place, tag, children } = props;
//     const anchorName = 'tag-menu-container-' + tag.id;

//     return (
//         <span className={anchorName}>
//             {anchor}
//             <ReactTooltip
//                 className={classNames(
//                     '!text-black-75 !opacity-100 !bg-white !filter !shadow-swegon1 !rounded-none !z-10',
//                     '!text-black-75 !opacity-100 !bg-white !filter !shadow-swegon1 !rounded-none !z-10',
//                 )}
//                 classNameArrow={classNames('!border-2 !border-white')}
//                 anchorSelect={'.' + anchorName}
//                 place={place}
//                 openOnClick="true"
//                 arrowColor={tailwindResolvedConfig.theme.colors.black[2]}
//                 clickable="true"
//             >
//                 {children}
//             </ReactTooltip>
//         </span>
//     );
// };

export const ProjectTagsFilter = (props) => {
    const { tags, setFilteredTags } = props;

    //unique tag types to be displayed by project filter
    const uniqueEquipmentTypes = [
        ...new Set(tags?.map((tag) => tag.equipmentType)),
    ];
    const uniqueRoomTypes = [...new Set(tags?.map((tag) => tag.room))];
    const uniqueValueTypes = [...new Set(tags?.map((tag) => tag.value))];

    //state of the project filter
    const [selectedEquipmentTypes, setSelectedEquipmentTypes] =
        useState(uniqueEquipmentTypes);
    const [selectedRoomTypes, setSelectedRoomTypes] = useState(uniqueRoomTypes);
    const [selectedValueTypes, setSelectedValueTypes] =
        useState(uniqueValueTypes);

    //toggles what tags are selected
    const toggleEquipmentType = (equipmentType) => {
        if (selectedEquipmentTypes.includes(equipmentType)) {
            setSelectedEquipmentTypes(
                selectedEquipmentTypes.filter((type) => type !== equipmentType),
            );
        } else {
            setSelectedEquipmentTypes([
                ...selectedEquipmentTypes,
                equipmentType,
            ]);
        }
    };
    const toggleRoomType = (roomType) => {
        if (selectedRoomTypes.includes(roomType)) {
            setSelectedRoomTypes(
                selectedRoomTypes.filter((type) => type !== roomType),
            );
        } else {
            setSelectedRoomTypes([...selectedRoomTypes, roomType]);
        }
    };
    const toggleValueType = (valueType) => {
        if (selectedValueTypes.includes(valueType)) {
            setSelectedValueTypes(
                selectedValueTypes.filter((type) => type !== valueType),
            );
        } else {
            setSelectedValueTypes([...selectedValueTypes, valueType]);
        }
    };

    //filtered tags from parent is reloaded when selections change in this component
    useEffect(() => {
        setFilteredTags(
            tags?.filter(
                (tag) =>
                    selectedEquipmentTypes.includes(tag.equipmentType) &&
                    selectedRoomTypes.includes(tag.room) &&
                    selectedValueTypes.includes(tag.value),
            ),
        );
    }, [
        selectedEquipmentTypes,
        selectedRoomTypes,
        selectedValueTypes,
        setFilteredTags,
        tags,
    ]);

    //when the tags change the selection of filters is reloaded
    useEffect(() => {
        setSelectedEquipmentTypes(uniqueEquipmentTypes);
        setSelectedRoomTypes(uniqueRoomTypes);
        setSelectedValueTypes(uniqueValueTypes);
    }, [tags]);

    return (
        <ToolTip
            anchor={<div>Filter <FontAwesomeIcon icon="fa-solid fa-filter" /></div>}
            place={'bottom-end'}
        >
            <div>
                <div>
                    <div className="top-0 left-0 mb-1">Equipment</div>
                    {uniqueEquipmentTypes.map((equipmentType) => (
                        <div
                            className="m-1"
                            key={equipmentType}
                        >
                            <CheckboxField
                                value={equipmentType}
                                label={equipmentType}
                                checked={selectedEquipmentTypes.includes(
                                    equipmentType,
                                )}
                                onChange={() =>
                                    toggleEquipmentType(equipmentType)
                                }
                            />
                        </div>
                    ))}
                </div>
                <div>
                    <div className="top-0 left-0 mb-1">Room/Location</div>
                    {uniqueRoomTypes.map((roomType) => (
                        <div
                            className="m-1"
                            key={roomType}
                        >
                            <CheckboxField
                                value={roomType}
                                label={roomType}
                                checked={selectedRoomTypes.includes(roomType)}
                                onChange={() => toggleRoomType(roomType)}
                            />
                        </div>
                    ))}
                </div>
                <div>
                    <div className="top-0 left-0 mb-1">Tags</div>
                    {uniqueValueTypes.map((valueType) => (
                        <div
                            className="m-1"
                            key={valueType}
                        >
                            <CheckboxField
                                value={valueType}
                                label={valueType}
                                checked={selectedValueTypes.includes(valueType)}
                                onChange={() => toggleValueType(valueType)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </ToolTip>
    );
};
