import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as storeExt from '../store';
import { Formik, Field } from 'formik';
import { ChevronButton } from './ChevronButton';
import lodashOrderBy from 'lodash/orderBy';

const {
    useGetUsersQuery,
    useGetRolesQuery,
    useGetConfigQuery,
    useUpdateUserMutation,
    mainApi,
} = storeExt;

export const UserDetailTabs = (props) => {
    const { selectedUser, setSelectedUser } = props;
    const { data: users } = useGetUsersQuery();
    const { data: roles } = useGetRolesQuery();
    const { data: config } = useGetConfigQuery();
    const [updateUserInfo] = useUpdateUserMutation();
    const userData = React.useMemo(() => {
        if (selectedUser) {
            const user = users.find((x) => x.id === selectedUser);
            return user ?? null;
        }
        return null;
    }, [selectedUser, users]);
    const [openProfile, setOpenProfile] = useState(false);
    const [openFeatures, setOpenFeatures] = useState(false);

    const dispatch = useDispatch();

    if (!userData && openProfile) {
        setOpenProfile(false);
    }
    if (!userData && openFeatures) {
        setOpenFeatures(false);
    }

    const rolesWithOrder = useMemo(() => {
        if (!roles) {
            return null;
        }
        if (!config?.configUserFeatures) {
            return roles;
        }
        return roles.map((item) => {
            const featureItem = config?.configUserFeatures.find((x) => {
                return x.code === item.code;
            });
            return {
                ...item,
                ordinal: featureItem?.ordinal ?? 2000,
            };
        });
    }, [roles, config?.configUserFeatures]);

    const orderedRoles = lodashOrderBy(rolesWithOrder, ['ordinal'], ['asc']);

    const userRoles = useMemo(() => {
        if (!userData) {
            return [];
        }
        return userData.roles
            .map((item) => {
                return item.code;
            })
            .reduce(function (acc, curr) {
                acc.push(curr);
                return acc;
            }, []);
    }, [userData]);

    const handleSubmit = async (values, formikHelpers) => {
        updateUserInfo(values);
        formikHelpers.setSubmitting(false);
        setSelectedUser(null);
        dispatch(
            mainApi.util.prefetch('getUsers', { force: true, }),
        );
    };

    return (
        <div className="container bg-white h-full flex flex-col">
            {userData
                ? (<Formik
                        enableReinitialize
                        initialValues={{...userData, rolesChecked: userRoles}}
                        validate={handleValidate}
                        onSubmit={handleSubmit}
                    >
                    {(formik) => (
                        <form className="overflow-auto" onSubmit={formik.handleSubmit}>
                            <div className="px-4 pt-4">
                                <RenderProfileInfo
                                    openProfile={openProfile}
                                    setOpenProfile={setOpenProfile}
                                    values={formik.values}
                                />
                                <RenderFeatures
                                    openFeatures={openFeatures}
                                    setOpenFeatures={setOpenFeatures}
                                    orderedRoles={orderedRoles}
                                />
                            </div>
                            <div className="flex justify-end p-4">
                                <button
                                    className="swegon-like-button contained"
                                    type="submit"
                                    disabled={formik.isSubmitting || !formik.dirty}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                    </Formik>)
                : null
                }
        </div>
    );
};

const RenderProfileInfo = (props) => {
    const { openProfile, setOpenProfile, values } = props;

    return (
        <React.Fragment>
            <DetailHeader
                openState={openProfile}
                clickHandler={setOpenProfile}
                name={'Profile'}
            />
            { openProfile
                ? (<div className="pl-4 pr-4">
                    <div>
                        <Field type="checkbox" name="isActive" />
                        <label className="ml-4" htmlFor="isActive">Is Active</label>
                    </div>
                    <TextInput
                        fieldName='givenName'
                        label='Given Name'
                        srcValue={values.givenName}
                    />
                    <TextInput
                        fieldName='surname'
                        label='Surname'
                        srcValue={values.surname}
                    />
                    <TextInput
                        fieldName='email'
                        label='E-mail'
                        srcValue={values.email}
                    />
                    <TextInput
                        fieldName='company'
                        label='Company'
                        srcValue={values.company}
                    />
                    </div>)
                : null
            }
        </React.Fragment>
    );
};

const RenderFeatures = (props) => {
    const { openFeatures, setOpenFeatures, orderedRoles } = props;

    return (
        <React.Fragment>
            <DetailHeader
                openState={openFeatures}
                clickHandler={setOpenFeatures}
                name={'Features'}
            />
            { openFeatures
                ? (<React.Fragment>
                        <div id="checkbox-group"></div>
                        <div className="pl-4 pr-4" role="group" aria-labelledby="checkbox-group">
                            {orderedRoles.map((x) => (
                                <div key={x.code}>
                                    <Field type="checkbox" name="rolesChecked" value={x.code} />
                                    <label className="ml-4">{x.label}</label>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>)
                : null
            }
        </React.Fragment>
    );
};

const DetailHeader = (props) => {
    const { openState, clickHandler, name} = props;

    return (
        <div className="flex flex-wrap">
            <div className="w-6 content-center">
                <ChevronButton
                    open={openState}
                    onClick={() => clickHandler(!openState)}
                />
            </div>
            <div className="text-lg font-bold">
                {name}
            </div>
        </div>
    );
}
const TextInput = (props) => {
    const { fieldName, label, srcValue, onChange } = props;

    return (
        <div className="pb-4">
            <div className="text-sm font-bold">
                <label
                    className="block mb-2"
                    htmlFor={fieldName}
                >
                    {label}
                </label>
            </div>
            <div className="swegon-ui-text-input-wrapper">
                { onChange
                    ? (<input
                            type="text"
                            name={fieldName}
                            value={srcValue ?? ''}
                            onChange={onChange}
                        />)
                    : (<input
                            type="text"
                            name={fieldName}
                            value={srcValue ?? ''}
                            readOnly={true}
                        />)
                }
            </div>
        </div>
    );
}

const handleValidate = (values) => {
    let errors = {};
    return errors;
};