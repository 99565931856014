import React, { useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
//    useCreateTagItemMutation,
    useGetConfigQuery,
//    useGetMyProjectsQuery,
//    useUpdateTagItemMutation,
    useCreateProjectTagItemMutation,
//    useCreateProjectTagTakeoffMutation,
    useUpdateProjectTagItemMutation,
    useDeleteProjectTagItemMutation,
    useGetProjectQuery,
    useGetMeQuery,
//    useGetProductsQuery,
    useCopyProjectTagItemMutation,
//    useBuildItemDocumentMutation,
    useSubmitProductDocJobMutation,
    useUpdateProjectTagItemQtyMutation,
    mainActions,
    mainSelectors,
    documentsApi,
} from '../store';
import { ToolTip } from '../components';
import {
    AddConfigProductModal,
    AddProductModal,
    DeleteTagModal,
    DuplicateTagModal,
    EditTagModal,
} from '../modals';
import * as utils from '../utils';
import { useParams } from 'react-router-dom';
import { ChevronButton } from './ChevronButton';
import lodashOrderBy from 'lodash/orderBy';
//import { v4 as uuidv4 } from 'uuid';

const TagsTableHeader = () => {
    return (
        <thead className="text-black-30">
            <tr>
                <th className="pl-1 pr-1 w-1">{'\xa0'}</th>
                <th className="pl-1 pr-4">Tag</th>
                <th className="px-4">Equipment Type</th>
                <th className="px-4">Room</th>
                <th className="px-4">Quantity</th>
                <th className="w-1">{'\xa0'}</th>
            </tr>
        </thead>
    );
};

const TagMenuItem = (props) => {
    const { icon, text, saveHandler, tagName, configId } = props;

    const [, setCpqData] = utils.cpqDataState.useStateContext();

    const { projectId } = useParams();
    const { data: project } = useGetProjectQuery(projectId);
    const { data: currentUser } = useGetMeQuery();
    const { data: config } = useGetConfigQuery();
    const { extQuoteInfo } = project;

    const projectInfo = React.useMemo(() => {
        let currency = '';
        if (
            extQuoteInfo &&
            extQuoteInfo.currency &&
            extQuoteInfo.currency !== ''
        ) {
            currency = extQuoteInfo.currency;
        } else {
            currency =
                currentUser?.settings?.find((x) => x.key === 'currency')
                    ?.value ?? 'USD';
        }
        return { name: project.name, currency: currency };
    }, [project, extQuoteInfo, currentUser]);

    return (
        <div
            className="mb-1 flex flex-row text-black-90 cursor-pointer mt-2"
            onClick={() => {
                setCpqData({
                    showModal: true,
                    configuratorId: configId,
                    onSubmit: saveHandler,
                    projectInfo: projectInfo,
                    projectSiteInfoJson: project.siteInfoConfiguratorJson,
                    onConfiguratorLoaded: (conf) => {
                        switch (configId) {
                            case config?.configurators.CTB:
                                conf.setFields({
                                    'fEquipment Tag': tagName,
                                });
                                break;
                            default:
                                conf.setFields({ fTag: tagName });
                                // conf.setFields({ fCity: 'New York' });
                                break;
                        }
                    },
                });
            }}
        >
            <div className="w-10">{icon}</div>
            <div>{text}</div>
        </div>
    );
};

const TagRow = (props) => {
    const { tag, projectId } = props;

    const dispatch = useDispatch();

    const [newEditTagModalOpen, setNewEditTagModalOpen] = useState(false);
    const [newDuplicateTagModalOpen, setNewDuplicateTagModalOpen] =
        useState(false);
    const [newDeleteTagModalOpen, setNewDeleteTagModalOpen] = useState(false);
    const [newAddProductModalOpen, setNewAddProductModalOpen] = useState(false);
    const [newAddConfigProdModalOpen, setNewAddConfigProdModalOpen] =
        useState(false);

    const [open, setOpen] = useState(false);
    const { data: config } = useGetConfigQuery();
    const [createTagItem, createTagItemResult] =
        useCreateProjectTagItemMutation();
//    const [createTagTakeoff, createTagTakeoffResult] =
//        useCreateProjectTagTakeoffMutation();

    const saveNewItem = async (value) => {
        const itemQty =
            value?.fields?.find((x) => x.name === 'fQuantity')?.value ?? 1;
        const itemFix = value?.fields?.find((x) => x.name === 'rebarSelection');
        if (itemFix) {
            itemFix.stringValue = itemFix.stringValue + '';
        }
        dispatch(
            mainActions.showWaitingModal({
                isSimple: true,
                msg: 'Saving new product item ...',
            }),
        );
        try {
            await createTagItem({
                id: tag.projectId,
                tagId: tag.id,
                qty: itemQty,
                config: JSON.stringify(value),
            }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
        }
    };

    const hasItemToValidate = React.useMemo(() => {
        return tag.items.some((item) => item.needValidate);
    }, [tag.items]);

    const hasItemNeedAction = React.useMemo(() => {
        return tag.items.some((item) => item.needAction);
    }, [tag.items]);

    const takeoff = {
        icon: <FontAwesomeIcon icon="fa-solid fa-plus" />,
        text: 'Takeoff',
        configId: config?.configurators.Takeoff,
    };

    const showTagWithWarning = (tagName) => {
        return (
            <div className="flex flex-row">
                <div>{tagName}</div>
                <div className="ml-2 text-yellow-100">{'*'}</div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <tr className="border-b border-t border-black-20 font-bold">
                <td className="pl-1 pr-1">
                    <ChevronButton
                        open={open}
                        onClick={() => setOpen(!open)}
                    />
                </td>
                <td className="pl-1 pr-4">
                    {hasItemToValidate || hasItemNeedAction
                        ? showTagWithWarning(tag.value)
                        : tag.value}
                </td>
                <td className="px-4">{tag.equipmentType}</td>
                <td className="px-4">{tag.room}</td>
                <td className="px-4">1</td>
                <td>
                    <ToolTip
                        anchor={
                            <div>
                                <FontAwesomeIcon icon="fa-solid fa-ellipsis" />
                            </div>
                        }
                        place="bottom-start"
                        noArrow="true"
                    >
                        <div className="divide-y-2 divide-solid">
                            <div className="mb-2">
                                <TagManager
                                    tag={tag}
                                    projectId={projectId}
                                    setNewEditTagModalOpen={
                                        setNewEditTagModalOpen
                                    }
                                    setNewDuplicateTagModalOpen={
                                        setNewDuplicateTagModalOpen
                                    }
                                    setNewDeleteTagModalOpen={
                                        setNewDeleteTagModalOpen
                                    }
                                    setNewAddProductModalOpen={
                                        setNewAddProductModalOpen
                                    }
                                    setNewAddConfigProdModalOpen={
                                        setNewAddConfigProdModalOpen
                                    }
                                />
                            </div>
                            <div className="mt-2">
                                <TagMenuItem
                                    key={takeoff.text}
                                    icon={takeoff.icon}
                                    text={takeoff.text}
                                    configId={takeoff.configId}
                                    saveHandler={saveNewItem}
                                    tagName={takeoff.value}
                                />
                            </div>
                        </div>
                    </ToolTip>
                </td>
                <td>
                    <EditTagModal
                        show={newEditTagModalOpen}
                        onClose={() => setNewEditTagModalOpen(false)}
                        projectId={projectId}
                        tag={tag}
                    />
                    <DuplicateTagModal
                        show={newDuplicateTagModalOpen}
                        onClose={() => setNewDuplicateTagModalOpen(false)}
                        projectId={projectId}
                        tag={tag}
                    />
                    <DeleteTagModal
                        show={newDeleteTagModalOpen}
                        onClose={() => setNewDeleteTagModalOpen(false)}
                        tag={tag}
                        projectId={projectId}
                    />
                    <AddProductModal
                        show={newAddProductModalOpen}
                        onClose={() => setNewAddProductModalOpen(false)}
                        tag={tag}
                        projectId={projectId}
                    />
                    <AddConfigProductModal
                        show={newAddConfigProdModalOpen}
                        onClose={() => setNewAddConfigProdModalOpen(false)}
                        tag={tag}
                        projectId={projectId}
                        saveHandler={saveNewItem}
                    />
                </td>
            </tr>
            {open ? (
                <ProductRows
                    tag={tag}
                    projectId={projectId}
                />
            ) : null}
        </React.Fragment>
    );
};

const TagManager = (props) => {
    const {
        tag,
        projectId,
        setNewEditTagModalOpen,
        setNewDuplicateTagModalOpen,
        setNewDeleteTagModalOpen,
        setNewAddProductModalOpen,
        setNewAddConfigProdModalOpen,
    } = props;

    return (
        <>
            <div className="divide-y-2 divide-solid">
                <ul>
                    <li className="mb-4">
                        <button onClick={() => setNewEditTagModalOpen(true)}>
                            <FontAwesomeIcon
                                className="mr-6"
                                icon="fa-solid fa-pen"
                            />
                            Edit Tag
                        </button>
                    </li>
                    <li className="mb-4">
                        <button
                            onClick={() => setNewDuplicateTagModalOpen(true)}
                        >
                            <FontAwesomeIcon
                                className="mr-6"
                                icon="fa-solid fa-copy"
                            />
                            Duplicate Tag
                        </button>
                    </li>
                    <li className="mb-2">
                        <button onClick={() => setNewDeleteTagModalOpen(true)}>
                            <FontAwesomeIcon
                                className="mr-6"
                                icon="fa-solid fa-trash-can"
                            />
                            Delete Tag
                        </button>
                    </li>
                </ul>
                <ul>
                    <li className="mt-2 mb-2">
                        <button onClick={() => setNewAddProductModalOpen(true)}>
                            <FontAwesomeIcon
                                className="mr-6"
                                icon="fa-solid fa-plus"
                            />
                            Add Product
                        </button>
                    </li>
                </ul>
                <ul>
                    <li className="mt-2">
                        <button
                            onClick={() => setNewAddConfigProdModalOpen(true)}
                        >
                            <FontAwesomeIcon
                                className="mr-6"
                                icon="fa-solid fa-plus"
                            />
                            Add Configured Product
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
};

const OutputManger = (props) => {
    const { menu, onBuildDoc } = props;

    return (
        <ToolTip
            anchor={
                <div className="mt-2 flex flex-row">
                    <FontAwesomeIcon
                        className="mr-6"
                        icon="fa-regular fa-file"
                    />
                    <div className="grow">Outputs</div>
                    <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                </div>
            }
            place="right-start"
            noArrow="true"
        >
            {menu.map((item) => (
                <li
                    className="mt-2 mb-2"
                    key={item.ordinal}
                >
                    <button
                        onClick={() =>
                            onBuildDoc({
                                buildType: item.buildType,
                                isSaleSubmittal: item.ordinal === 0,
                            })
                        }
                    >
                        <FontAwesomeIcon
                            className="mr-6"
                            icon={item.icon}
                        />
                        {item.display}
                    </button>
                </li>
            ))}
        </ToolTip>
    );
};

const ProductManager = (props) => {
    const {
        productId,
        prodConfig,
        onEdit,
        onSave,
        onDelete,
        onCopy,
        onBuildDoc,
    } = props;

    const [cpqData, setCpqData] = utils.cpqDataState.useStateContext();

    const configProdId = prodConfig ? prodConfig.idProduct : null;
    const { data: config } = useGetConfigQuery();

    const outputs = React.useMemo(() => {
        if (configProdId) {
            var entry;
            switch (configProdId) {
                case config?.configurators.ARTR:
                    entry = config?.productDocuments.ARTR;
                    break;
                case config?.configurators.CIB:
                    entry = config?.productDocuments.CIB;
                    break;
                case config?.configurators.CTB:
                    entry = config?.productDocuments.CTB;
                    break;
                case config?.configurators.IsolatedInertiaBase:
                    entry = config?.productDocuments.IsolatedInertiaBase;
                    break;
                case config?.configurators.PipeRiser:
                    entry = config?.productDocuments.PipeRiser;
                    break;
                case config?.configurators.IsolatorSelection:
                    entry = config?.productDocuments.IsolatorSelection;
                    break;
                default:
            }
            return entry;
        }
        return null;
    }, [configProdId, config]);

    const isFullScreenProd = (configId) =>
        config?.fullScreenConfigurators.indexOf(configId, 0) >= 0;

    return (
        <ToolTip
            anchor={<FontAwesomeIcon icon="fa-solid fa-ellipsis" />}
            place="right"
            noArrow="true"
        >
            <div className="divide-y-2 divide-solid">
                <ul>
                    {productId ? (
                        <li className="mb-4">
                            <button
                                onClick={() => {
                                    setCpqData({
                                        showModal: true,
                                        configuratorId: productId,
                                        onSubmit: onSave,
                                        fullScreen: isFullScreenProd(productId),
                                        onConfiguratorLoaded: (conf) =>
                                            conf.setConfiguredProduct(
                                                prodConfig,
                                            ),
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    className="mr-6"
                                    icon="fa-solid fa-pen"
                                />
                                Edit Product
                            </button>
                        </li>
                    ) : null}
                    <li className="mb-4">
                        <button onClick={() => onCopy()}>
                            <FontAwesomeIcon
                                className="mr-6"
                                icon="fa-solid fa-copy"
                            />
                            Duplicate Product
                        </button>
                    </li>
                    <li className="mb-2">
                        <button onClick={() => onDelete()}>
                            <FontAwesomeIcon
                                className="mr-6"
                                icon="fa-solid fa-trash-can"
                            />
                            Delete Product
                        </button>
                    </li>
                </ul>
                {outputs ? (
                    <ul>
                        <OutputManger
                            menu={outputs}
                            onBuildDoc={onBuildDoc}
                        />
                    </ul>
                ) : null}
            </div>
        </ToolTip>
    );
};

const ProductContent = (props) => {
    const { firstRow, secondRow, indent, needValidate, needAction } = props;
    const className = indent == null ? 'px-4' : 'px-8';

    const getClassName = (needValidate, needAction) => {
        if (needValidate) {
            return 'flex flex-row font-bold text-yellow-100';
        }
        if (needAction) {
            return 'flex flex-row font-bold text-red-100';
        }
        return 'flex flex-row font-bold';
    };

    return (
        <div className={className}>
            {needValidate || needAction ? (
                <div className={getClassName(needValidate, needAction)}>
                    <div>{firstRow}</div>
                    <div className="ml-2">{'*'}</div>
                </div>
            ) : (
                <div className="font-bold">{firstRow}</div>
            )}
            <div className="text-sm text-black-30">{secondRow}</div>
        </div>
    );
};

const submittalMsg =
    'Generating Sale Submittal\nPlease wait as it will take a while .....';
const calSummaryMsg = 'Generating Calculation Summary ...';

const ProductRow = (props) => {
    const { tag, tagItem } = props;

    const dispatch = useDispatch();

    const selectedItemId = useSelector(mainSelectors.getSelectedItemId);
    const [, setCpqData] = utils.cpqDataState.useStateContext();

    const [qtyValue, setQtyValue] = useState(tagItem.quantity);
    const [mouseEnetered, setMouseEntered] = useState(false);
    const [updateTagItem, updateTagItemResult] =
        useUpdateProjectTagItemMutation();
    const [updateTagItemQty, updateProjectTagItemQtyResult] =
        useUpdateProjectTagItemQtyMutation();
    const [deleteTagItem, deleteTagItemResult] =
        useDeleteProjectTagItemMutation();
    const [copyTagItem, copyTagItemResult] = useCopyProjectTagItemMutation();
//    const [buildDocument, buildDocumentResult] = useBuildItemDocumentMutation();
    const [submitBuildJob, submitBuildJobResult] =
        useSubmitProductDocJobMutation();
    const configObj = tagItem.configuratorJson
        ? JSON.parse(tagItem.configuratorJson)
        : null;

    let productId = configObj?.idProduct;
    const prodConfig = configObj?.configuredProduct;

    const saveItem = async (value) => {
        const itemQty =
            value?.fields?.find((x) => x.name === 'fQuantity')?.value ?? 1;
        const itemFix = value?.fields?.find((x) => x.name === 'rebarSelection');
        if (itemFix) {
            itemFix.stringValue = itemFix.stringValue + '';
        }
        dispatch(
            mainActions.showWaitingModal({
                isSimple: true,
                msg: 'Saving product item ...',
            }),
        );
        try {
            await updateTagItem({
                id: tag.projectId,
                tagId: tag.id,
                qty: itemQty,
                config: JSON.stringify(value),
                itemId: tagItem.id,
            }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
        }
    };

    const saveItemQty = async (value) => {
        if (value !== tagItem.quantity) {
            console.log('Value passed to routine:');
            console.log(value);
            dispatch(
                mainActions.showWaitingModal({
                    isSimple: true,
                    msg: 'Saving product item quantity ...',
                }),
            );
            try {
                await updateTagItemQty({
                    id: tag.projectId,
                    tagId: tag.id,
                    qty: value,
                    itemId: tagItem.id,
                }).unwrap();
            } catch (err) {
                console.log(err);
            } finally {
                dispatch(mainActions.hideWaitingModal());
            }
        }
    };

    const deleteItem = async () => {
        dispatch(
            mainActions.showWaitingModal({
                isSimple: true,
                msg: 'Deleting product item ...',
            }),
        );
        try {
            await deleteTagItem({
                id: tag.projectId,
                tagId: tag.id,
                itemId: tagItem.id,
            }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
        }
    };

    const copyItem = async () => {
        dispatch(
            mainActions.showWaitingModal({
                isSimple: true,
                msg: 'Copying product item ...',
            }),
        );
        try {
            await copyTagItem({
                id: tag.projectId,
                tagId: tag.id,
                itemId: tagItem.id,
            }).unwrap();
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
        }
    };

    const genDocument = async (value) => {
        const { buildType, isSaleSubmittal } = value;
        if (!isSaleSubmittal) {
            dispatch(
                mainActions.showWaitingModal({
                    isSimple: true,
                    msg: calSummaryMsg,
                }),
            );
        } else {
            dispatch(
                mainActions.showWaitingModal({
                    isSimple: false,
                    msg: submittalMsg,
                }),
            );
        }
        try {
            /*
            await buildDocument({
                id: tag.projectId,
                tagId: tag.id,
                itemId: tagItem.id,
                buildType: buildType,
            }).unwrap();
            */
            await submitBuildJob({
                id: tag.projectId,
                tagId: tag.id,
                itemId: tagItem.id,
                buildType: buildType,
            }).unwrap();
            setCpqData({ showModal : false, itemNeedRefresh: tagItem.id });
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(mainActions.hideWaitingModal());
            dispatch(documentsApi.util.prefetch('getProductHasJobInProgress',
                tagItem.id, { force: true, }));
        }
    };

    const isSelected = React.useMemo(() => {
        if (selectedItemId === tagItem.id) {
            return true;
        }
        return false;
    }, [tagItem.id, selectedItemId]);

    const onClickHandler = (isSelected, selectedItemId) => {
        if (!isSelected) {
            dispatch(mainActions.setSelectedItemId(selectedItemId));
            setQtyValue(tagItem.quantity);
        } else {
            dispatch(mainActions.setSelectedItemId(null));
            setMouseEntered(false);
        }
    };

    const numFieldClickHandler = (event) => {
        event.stopPropagation();
    };

    const numFieldValueChangeHandler = (event) => {
        if (event.target.value === "") {
            console.log('Empty string')
            setQtyValue(0);
        }
        if (parseInt(event.target.value) < 1) {
            console.log('-ve value');
            setQtyValue(0);
        }
        setQtyValue(+event.target.value);
    };

    const keyUpHandler = (event) => {
        if (event.key === 'Enter') {
            if (qtyValue >= 1) {
                saveItemQty(qtyValue);
            }
            setMouseEntered(false);
        }
    };

    const mouseLeaveHandler = () => {
        if (mouseEnetered) {
            if (qtyValue >= 1) {
                saveItemQty(qtyValue);
            }
            setMouseEntered(false);
        }
    }

    if (tagItem.name === 'Takeoff') {
        const wrapper = utils.buildConfiguratorWrapper(
            tagItem.configuratorJson,
        );

        const takeoffProducts = wrapper.getTakeoffProducts();
        const fEquipmentType = wrapper.getFieldStringValue('fEquipmentType');
        const fTrade = wrapper.getFieldStringValue('fTrade');
        const fMountLocation = wrapper.getFieldStringValue('fMountLocation');

        const fIsSeismic = wrapper.getFieldValue('fIsSeismic', 'booleanValue')
            ? 'Seismic'
            : 'Not Seismic';

        return (
            <>
                <tr
                    className={classNames({
                        'text-white bg-green-dark': isSelected,
                    })}
                    onClick={() => {
                        onClickHandler(isSelected, tagItem.id);
                    }}
                >
                    <td className="pl-1 pr-1">&nbsp;</td>
                    <td colSpan="3">
                        <ProductContent
                            firstRow={'Takeoff'}
                            secondRow={`${fEquipmentType}, ${fTrade}, ${fMountLocation}, ${fIsSeismic}`}
                        />
                    </td>
                    <td
                        className="px-4 w-4"
                        onClick={() => {
                            onClickHandler(isSelected, tagItem.id);
                            setMouseEntered(!mouseEnetered);
                        }}
                        onMouseLeave={mouseLeaveHandler}
                    >
                        <div
                            className="swegon-ui-text-input-wrapper"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!isSelected) {
                                    onClickHandler(isSelected, tagItem.id);
                                } else if (!mouseEnetered) {
                                    setMouseEntered(true);
                                    setQtyValue(tagItem.quantity);
                                }
                            }}
                        >
                            {isSelected && mouseEnetered
                                ? (<input
                                        type="number"
                                        defaultValue={qtyValue}
                                        min={1}
                                        onClick={numFieldClickHandler}
                                        onChange={numFieldValueChangeHandler}
                                        onKeyUp={keyUpHandler}
                                    />)
                                : tagItem.quantity
                            }
                        </div>
                    </td>
                    <td colSpan="3">
                        <ProductManager
                            productId={productId}
                            prodConfig={prodConfig}
                            onSave={saveItem}
                            onDelete={deleteItem}
                            onCopy={copyItem}
                        />
                    </td>
                </tr>
                {takeoffProducts.map((product, index) => (
                    <tr
                        key={index}
                        className={classNames({
                            'text-black bg-green-secondary': isSelected,
                        })}
                        onClick={() => {
                            onClickHandler(isSelected, tagItem.id);
                        }}
                    >
                        <td className="pl-1 pr-1">&nbsp;</td>
                        <td colSpan="3">
                            <ProductContent
                                firstRow={product.name}
                                secondRow={null}
                            />
                        </td>
                        <td className="px-4">{product.quantity}</td>
                        <td colSpan="3"></td>
                    </tr>
                ))}
            </>
        );
    }

    return (
        <tr className={classNames({ 'text-white bg-green-dark': isSelected })}>
            <td
                className="pl-1 pr-1"
                onClick={() => {
                    onClickHandler(isSelected, tagItem.id);
                }}
            >
                &nbsp;
            </td>
            <td
                colSpan="3"
                onClick={() => {
                    onClickHandler(isSelected, tagItem.id);
                }}
            >
                <ProductContent
                    firstRow={tagItem.name}
                    secondRow={tagItem.info}
                    needValidate={tagItem.needValidate}
                    needAction={tagItem.needAction}
                />
            </td>
            <td
                className="px-4 w-4"
                onClick={() => {
                    onClickHandler(isSelected, tagItem.id);
                    setMouseEntered(!mouseEnetered);
                }}
                onMouseLeave={mouseLeaveHandler}
            >
                <div
                    className="swegon-ui-text-input-wrapper"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (!isSelected) {
                            onClickHandler(isSelected, tagItem.id);
                        } else if (!mouseEnetered) {
                            setMouseEntered(true);
                            setQtyValue(tagItem.quantity);
                        }
                    }}
                >
                {isSelected && mouseEnetered
                    ? (<input
                            type="number"
                            defaultValue={qtyValue}
                            min={1}
                            onClick={numFieldClickHandler}
                            onChange={numFieldValueChangeHandler}
                            onKeyUp={keyUpHandler}
                        />)
                    : tagItem.quantity
                }
                </div>
            </td>
            <td>
                <ProductManager
                    productId={productId}
                    prodConfig={prodConfig}
                    onSave={saveItem}
                    onDelete={deleteItem}
                    onCopy={copyItem}
                    onBuildDoc={genDocument}
                />
            </td>
        </tr>
    );
};

const ProductRows = (props) => {
    const { tag, projectId } = props;

    const tagItems = lodashOrderBy(tag.items.length > 0 ? tag.items : [], 'sequenceId');

    if (tagItems.length > 0) {
        return tagItems.map((tagItem) => (
            <ProductRow
                key={tagItem.id}
                tag={tag}
                tagItem={tagItem}
                projectId={projectId}
            />
        ));
    }
    return (
        <tr>
            <td className="pl-1 pr-1">&nbsp;</td>
            <td colSpan="5">
                <ProductContent
                    firstRow="No product to show"
                    secondRow=""
                />
            </td>
        </tr>
    );
};

const TagsTableBody = (props) => {
    const { tags: propsTags, projectId } = props;

    const tags = propsTags ?? [];

    return (
        <tbody>
            {tags.map((tag) => (
                <TagRow
                    key={tag.id}
                    tag={tag}
                    projectId={projectId}
                />
            ))}
        </tbody>
    );
};

export const TagsTable = (props) => {
    const { tags, projectId } = props;

    return (
        <table className="tags-table">
            <TagsTableHeader />
            <TagsTableBody
                tags={tags}
                projectId={projectId}
            />
        </table>
    );
};
